import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  NzDropDownDirective,
  NzDropdownMenuComponent,
} from 'ng-zorro-antd/dropdown';
import { AvatarModule } from 'ngx-avatars';
import { NzDividerComponent } from 'ng-zorro-antd/divider';
import { JsonPipe } from '@angular/common';
import { NzTagComponent } from 'ng-zorro-antd/tag';
import { NzButtonComponent } from 'ng-zorro-antd/button';
import { MatButtonModule } from '@angular/material/button';
import { User } from '@buspro/web/core';

@Component({
  selector: 'buspro-admin-user-auth',
  standalone: true,
  imports: [
    MatButtonModule,
    NzDropDownDirective,
    AvatarModule,
    NzDropdownMenuComponent,
    NzDividerComponent,
    JsonPipe,
    NzTagComponent,
    NzButtonComponent,
  ],
  template: `
    @if (currentUser) {
    <div class="btn" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
      <ngx-avatars [name]="currentUser.name" size="36"></ngx-avatars>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div class="bg-white w-64 drop-shadow py-3 flex flex-col items-center">
        <div class="text-center pt-8 pb-4 text-gray-500 font-semibold">Administrative Console</div>
        <ngx-avatars size="100" name="Bernard White"></ngx-avatars>
        <div class="text-lg font-bold mt-2 mb-1">{{ currentUser.name }}</div>
        <nz-tag nzColor="success">
          <div class="font-bold uppercase">{{ currentUser.role }}</div>
        </nz-tag>

        <nz-divider></nz-divider>
        <div class="px-2">
          <button class="btn btn-warning btn-block w-full" (click)="logout()">
            Sign Out <span class="fe fe-log-out"></span>
          </button>
        </div>
      </div>
    </nz-dropdown-menu>
    } @else {
    <div class="skeleton h-8 w-8"></div>
    }
  `,
  styles: ``,
})
export class UserAuthComponent {

  @Input({ required: true }) currentUser!: User;

  @Output() loggingOut = new EventEmitter();

  logout() {
    this.loggingOut.emit();
  }
}


