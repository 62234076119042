import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'buspro-admin-tenants-sidemenu',
  standalone: true,
  imports: [RouterLink, RouterLinkActive],
  template: `
    <div class="bg-base-100 min-h-full">
      <div class="text-lg font-bold py-4 px-6">Tenant Menu</div>
      
      <ul class="menu menu-md bg-base-100 rounded-box">
        @for (menuItem of menu; track $index) {
          @if (menuItem.subRoutes) {
            <li>
              <details>
                <summary class="flex items-center gap-3">
                  @if (menuItem.icon) {
                    <i class="fe fe-{{ menuItem.icon }} w-4 h-4"></i>
                  }
                  <span>{{ menuItem.label }}</span>
                </summary>
                <ul>
                  @for (subItem of menuItem.subRoutes; track $index) {
                    <li>
                      <a 
                        [routerLink]="subItem.route" 
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        #rla="routerLinkActive"
                        class="flex items-center gap-3"
                      >
                        @if (subItem.icon) {
                          <i class="fe fe-{{ subItem.icon }} w-4 h-4"></i>
                        }
                        <span>{{ subItem.label }}</span>
                      </a>
                    </li>
                  }
                </ul>
              </details>
            </li>
          } @else {
            <li>
              <a 
                [routerLink]="menuItem.route" 
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                #rla="routerLinkActive"
                class="flex items-center gap-3"
              >
                @if (menuItem.icon) {
                  <i class="fe fe-{{ menuItem.icon }} w-4 h-4"></i>
                }
                <span>{{ menuItem.label }}</span>
              </a>
            </li>
          }
        }
      </ul>
    </div>
  `,
  styles: `
    :host {
      display: block;
      height: 100%;
    }

    .menu :where(li > *) {
      @apply text-base-content/70 transition-all duration-200 py-3;
    }

    .menu :where(li > .active) {
      @apply text-primary bg-primary/10 font-semibold border-l-4 border-primary;
    }

    .menu :where(li > *:hover) {
      @apply text-primary bg-primary/5;
    }

    /* Dropdown menu styles */
    .menu li details {
      @apply transition-all -ml-4 duration-200;
    }

    /* Active dropdown header */
    .menu li details[open] > summary {
      @apply text-primary bg-primary/10 font-semibold border-l-4 border-primary;
    }

    /* Dropdown container */
    .menu li details[open] > ul {
      @apply bg-base-100 py-2 shadow-sm mt-2 rounded-lg;
    }

    /* Active dropdown item */
    .menu li details[open] > ul li > a.active {
      @apply text-primary bg-primary/10 font-semibold border-l-4 border-primary;
    }

    /* Dropdown item hover */
    .menu li details[open] > ul li > a:hover {
      @apply text-primary bg-primary/5;
    }

    /* Summary arrow color */
    .menu summary::after {
      @apply text-base-content/50;
    }

    /* Open summary arrow color */
    .menu details[open] > summary::after {
      @apply text-primary;
    }

    /* Submenu indentation */
    .menu li > details > ul {
      @apply ml-4;
    }

    /* Remove default menu padding */
    .menu :where(li > *), 
    .menu :where(li > details > summary) {
      @apply px-4;
    }

    /* Ensure consistent icon sizing */
    i.fe {
      @apply flex items-center justify-center;
      font-size: 1.1rem;
    }

    /* Consistent text size */
    span {
      @apply text-sm;
    }
  `
})
export class TenantsSidemenuComponent {
  menu: ITenantAppMenu[] = [];

  @Input({ required: true })
  set tenantId(id: string) {
    this._tenantId = id;
    if (id) {
      this.menu = [
        {
          icon: 'grid',
          label: 'Dashboard',
          route: `/tenants/context/${id}/dashboard`,
        },
        {
          icon: 'activity',
          label: 'Monitor',
          route: `/tenants/context/${id}/monitor`,
        },
        {
          icon: 'truck',
          label: 'Operations',
          subRoutes: [
            {
              icon: 'calendar',
              label: 'Operation Schedules',
              route: `/tenants/context/${id}/schedules`,
            },
            {
              icon: 'play-circle',
              label: 'Active Operations',
              route: `/tenants/context/${id}/operations/active`,
            },
            {
              icon: 'check-circle',
              label: 'Completed Operations',
              route: `/tenants/context/${id}/operations/completed`,
            },
            {
              icon: 'file-text',
              label: 'Reports',
              route: `/tenants/context/${id}/reports`,
            },
          ],
        },
      
        {
          icon: 'book',
          label: 'Future Bookings',
          route: `/tenants/context/${id}/bookings/futures`,
        },

        {
          icon: 'package',
          label: 'Parcels',
          route: `/tenants/context/${id}/parcels`,
        },

        {
          icon: 'clipboard',
          label: 'Audits',
          route: `/tenants/context/${id}/audits`,
        },
        {
          icon: 'map',
          label: 'Routing',
          subRoutes: [
            {
              icon: 'home',
              label: 'Terminals',
              route: `/tenants/context/${id}/terminals`,
            },
            {
              icon: 'map-pin',
              label: 'Bus Stops',
              route: `/tenants/context/${id}/bus-stops`,
            },
            {
              icon: 'git-branch',
              label: 'Routes',
              route: `/tenants/context/${id}/routes`,
            }
          ],
        },
        {
          icon: 'truck',
          label: 'Fleets',
          subRoutes: [
            {
              icon: 'cpu',
              label: 'SIMS',
              route: `/tenants/context/${id}/sims`,
            },
            {
              icon: 'smartphone',
              label: 'Devices',
              route: `/tenants/context/${id}/devices`,
            },
            {
              icon: 'users',
              label: 'Drivers',
              route: `/tenants/context/${id}/drivers`,
            },
            {
              icon: 'user',
              label: 'Conductors',
              route: `/tenants/context/${id}/conductors`,
            },
            {
              icon: 'truck',
              label: 'Buses',
              route: `/tenants/context/${id}/assets`,
            },
          ],
        },
        {
          icon: 'users',
          label: 'Users',
          route: `/tenants/context/${id}/users`,
        },
      ];
    }
  }
  private _tenantId!: string;
}

interface ITenantAppMenu {
  icon?: string;
  label: string;
  route?: string;
  subRoutes?: ITenantAppMenu[];
}
