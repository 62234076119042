import { Component, inject } from '@angular/core';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { AsyncPipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { TenantInListDto } from '@buspro/shared/dtos';
import { AppService, LocalAuthService, TenantService } from '@buspro/web/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'buspro-admin-business-switcher',
  standalone: true,
  imports: [
    NzSelectComponent,
    NzOptionComponent,
    MatIconModule,
    FormsModule,
    AsyncPipe,
  ],
  template: `
    <nz-select
      class="w-96 bg-gray-200 rounded-lg"
      nzSize="large"
      [nzShowSearch]="true"
      [nzBorderless]="true"
      [ngModel]="selected"
      (ngModelChange)="onBusinessContextSwitch($event)"
      [nzLoading]="query.isLoading()"
      [nzPlaceHolder]="placeholderTpl"
      nzNotFoundContent="No Business Found"
      [nzCustomTemplate]="selectedDisplayTpl"
      [nzBackdrop]="true"
    >
      <ng-template #placeholderTpl>
        <span class="font-normal text-gray-400">Switch Business Context</span>
      </ng-template>
      <ng-template #selectedDisplayTpl let-selected>
        <div class="flex items-center w-full space-x-2">
          <mat-icon>business</mat-icon>
          <div class="font-bold">{{ selected.nzLabel }}</div>
        </div>
      </ng-template>

      @if (query.isSuccess()) { @for (tenant of tenants$|async; track tenant.id)
      {
      <nz-option [nzLabel]="tenant.name" [nzValue]="tenant"></nz-option>
      } }
    </nz-select>
  `,
  styles: ``,
})
export class BusinessSwitcherComponent {
  router = inject(Router);
  appService = inject(AppService);
  authService = inject(LocalAuthService);
  tenantService = inject(TenantService);
  tenants$ = new BehaviorSubject<TenantInListDto[]>([]);
  snackBar = inject(MatSnackBar);

  query = injectQuery(() => ({
    queryKey: ['tenants'],
    queryFn: () => this.tenantService.getTenants(),
    select: (data) => {
      // this.appService.currentTenant$.set(data.length ? data[0] : null)
      // this.selected = data.length ? data[0] : null
      this.tenants$.next(data);
    },
  }));
  
  selected = this.appService.currentTenant$();

  async onBusinessContextSwitch(nextTenant: TenantInListDto) {
    this.appService.currentTenant$.set(nextTenant);

    const interruptedUrl = this.authService.getInterruptedUrl();

    const currentTenantId = this.getTenantIdfromCurrentUrl(
      interruptedUrl || ''
    );

    if (currentTenantId === nextTenant.id) {
      this.snackBar.open(
        'You currently in the selected tenant profile',
        'OKAY'
      );
      return;
    }

    if (interruptedUrl && interruptedUrl?.startsWith('/tenants/context/')) {
      const lastPart = this.getLastPartOfUrl(interruptedUrl!);

      await this.router
        .navigateByUrl('/tenants/context/' + nextTenant.id + `/${lastPart}`)
        .then(() => window.location.reload());
    } else {
      await this.router
        .navigateByUrl('/tenants/context/' + nextTenant.id + '/overview')
        .then(() => window.location.reload());
    }
  }

  getLastPartOfUrl(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  getTenantIdfromCurrentUrl(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 2];
  }
}
