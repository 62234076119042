import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { NzModalComponent, NzModalContentDirective } from 'ng-zorro-antd/modal';
import {
  NzFormControlComponent,
  NzFormDirective,
  NzFormItemComponent,
  NzFormLabelComponent,
} from 'ng-zorro-antd/form';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzColDirective, NzRowDirective } from 'ng-zorro-antd/grid';
import { NzInputDirective, NzInputGroupComponent } from 'ng-zorro-antd/input';
import { NzOptionComponent, NzSelectComponent } from 'ng-zorro-antd/select';
import { NzDividerComponent } from 'ng-zorro-antd/divider';
import { injectMutation } from '@tanstack/angular-query-experimental';
import {
  FeFormMessagesService,
  FormMessageComponent,
  InputErrorDisplayComponent,
} from '@fulleffect/ngx-tailings';
import { NzSpinComponent } from 'ng-zorro-antd/spin';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NGXLogger } from 'ngx-logger';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { RegisterNewTenantDto } from '@buspro/shared/dtos';
import { ApiError, CacheKeys, TenantService } from '@buspro/web/core';

@Component({
  selector: 'buspro-admin-register-tenant-modal',
  standalone: true,
  imports: [
    NzModalComponent,
    NzModalContentDirective,
    NzFormDirective,
    NzFormItemComponent,
    ReactiveFormsModule,
    NzFormLabelComponent,
    NzFormControlComponent,
    NzColDirective,
    NzInputDirective,
    NzInputGroupComponent,
    NzSelectComponent,
    NzOptionComponent,
    NzRowDirective,
    NzDividerComponent,
    FormMessageComponent,
    InputErrorDisplayComponent,
    NzSpinComponent,
    DigitOnlyModule,
  ],
  template: `
    <nz-modal
      nzWidth="600px"
      (nzOnOk)="submitForm()"
      (nzOnCancel)="cancel.emit($event)"
      [nzVisible]="isVisible"
      nzTitle="Register New Business"
    >
      <ng-container *nzModalContent>
        <nz-spin [nzSpinning]="mutation.isPending()">
          <fe-form-message title="Ops! Something went wrong"></fe-form-message>
          <form
            nz-form
            class="mt-6"
            [formGroup]="form"
            (ngSubmit)="submitForm()"
          >
            <nz-form-item>
              <nz-form-label
                [nzSm]="8"
                [nzXs]="24"
                nzFor="companyName"
                [nzRequired]="true"
              >
                <span>Company Name</span>
              </nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24">
                <input
                  nz-input
                  id="companyName"
                  formControlName="companyName"
                />
                <fe-input-error-display
                  [control]="companyName"
                ></fe-input-error-display>
              </nz-form-control>
            </nz-form-item>

            <nz-divider
              nzText="Administrator OR Company Representative"
            ></nz-divider>
            <nz-form-item>
              <nz-form-label
                [nzSm]="8"
                [nzXs]="24"
                nzFor="firstName"
                [nzRequired]="true"
              >
                <span>First Name</span>
              </nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24">
                <input nz-input id="firstName" formControlName="firstName" />
                <fe-input-error-display
                  [control]="firstName"
                ></fe-input-error-display>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="surname" nzRequired>
                <span>Surname</span>
              </nz-form-label>
              <nz-form-control [nzSm]="14" [nzXs]="24">
                <input nz-input id="surname" formControlName="surname" />
                <fe-input-error-display
                  [control]="surname"
                ></fe-input-error-display>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="email"
                >E-mail Address</nz-form-label
              >
              <nz-form-control [nzSm]="14" [nzXs]="24">
                <input nz-input formControlName="email" id="email" />
                <fe-input-error-display
                  [control]="email"
                ></fe-input-error-display>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label
                [nzSm]="8"
                [nzXs]="24"
                nzFor="phoneNumber"
                nzRequired
                >Phone Number</nz-form-label
              >
              <nz-form-control
                [nzSm]="14"
                [nzXs]="24"
                [nzValidateStatus]="form.controls['phoneNumber']"
              >
                <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
                  <ng-template #addOnBeforeTemplate>
                    <nz-select
                      formControlName="phoneNumberPrefix"
                      class="!w-20"
                    >
                      <nz-option nzLabel="+256" nzValue="+256"></nz-option>
                    </nz-select>
                  </ng-template>
                  <input
                    formControlName="phoneNumber"
                    digitOnly
                    maxlength="10"
                    minlength="10"
                    autocomplete="off"
                    id="'phoneNumber'"
                    nz-input
                  />
                </nz-input-group>
                <fe-input-error-display
                  [control]="phoneNumber"
                ></fe-input-error-display>
              </nz-form-control>
            </nz-form-item>
          </form>
        </nz-spin>
      </ng-container>
    </nz-modal>
  `,
})
export class RegisterTenantModalComponent {
  private fb = inject(NonNullableFormBuilder);

  tenantService = inject(TenantService);
  feMessageService = inject(FeFormMessagesService);
  logger = inject(NGXLogger);
  snackBar = inject(MatSnackBar);

  @Input() isVisible!: boolean;
  @Output() cancel = new EventEmitter<boolean>();

  form: FormGroup<{
    companyName: FormControl<string>;
    firstName: FormControl<string>;
    surname: FormControl<string>;
    email: FormControl<string>;
    phoneNumberPrefix: FormControl<'+256' | '+233'>;
    phoneNumber: FormControl<string>;
  }> = this.fb.group({
    companyName: ['', [Validators.required]],
    firstName: ['', [Validators.required]],
    surname: ['', [Validators.required]],
    email: ['', [Validators.email, Validators.required]],
    phoneNumberPrefix: [
      { value: '+256' as '+256' | '+233', disabled: true },
      [],
    ],
    phoneNumber: [
      '',
      [Validators.required, Validators.minLength(9), Validators.maxLength(10)],
    ],
  });

  companyName = this.form.get('companyName');
  firstName = this.form.get('firstName');
  surname = this.form.get('surname');
  email = this.form.get('email');
  phoneNumber = this.form.get('phoneNumber');

  mutation = injectMutation((client) => ({
    mutationFn: (payload: RegisterNewTenantDto) =>
      this.tenantService.registerNewTenant(payload),
    onSuccess: async () => {
      await client.invalidateQueries({ queryKey: [CacheKeys.TENANTS] });
      this.cancel.emit(true);
      this.snackBar.open(
        `📫 Activation email has been sent to ${this.email!.value}`,
        'DONE',
        { duration: 5000 }
      );
      this.form.reset();
      this.feMessageService.emitError([]);
    },
    onError: async (error: ApiError) => {
      this.logger.error('Error on registering tenant', error);
      this.feMessageService.emitError(error?.error?.detail);
    },
  }));

  submitForm(): void {
    this.feMessageService.emitError([]);

    if (this.form.invalid) {
      Object.values(this.form.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });

      this.feMessageService.emitError(
        'Check and provide valid and required information'
      );
      return;
    }

    const phoneNumber = this.form.get('phoneNumber')!.value!;

    const payload: RegisterNewTenantDto = {
      companyName: this.form.get('companyName')!.value!,
      firstName: this.form.get('firstName')!.value!,
      surname: this.form.get('surname')!.value!,
      email: this.form.get('email')!.value,
      phoneNumber: this.form.get('phoneNumber')!.value,
    };

    this.mutation.mutate(payload);
  }
}
