import { Component, input, signal } from '@angular/core';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import {
  MatListItem,
  MatListItemIcon,
  MatListItemTitle,
  MatListModule,
} from '@angular/material/list';
import { NgIf } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MenuItem } from './admin-sidenav.component';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'buspro-admin-menu-item',
  standalone: true,
  imports: [
    MatIcon,
    MatListItem,
    MatListItemIcon,
    MatListItemTitle,
    MatListModule,
    MatIconModule,
    RouterLink,
    NgIf,
    RouterLinkActive,
  ],
  animations: [
    trigger('expandContractMenu', [
      transition(':enter', [
        style({ opacity: 0, height: '0px' }),
        animate('500ms ease-in-out', style({ opacity: 1, height: '*' })),
      ]),
      transition(':leave', [
        animate('500ms ease-in-out', style({ opacity: 0, height: '0px' })),
      ]),
    ]),
  ],
  template: `
    <a
      mat-list-item
      class="menu-item"
      (click)="toggleNested()"
      [routerLink]="item().route"
      routerLinkActive="selected-menu-item"
      #rla="routerLinkActive"
      [activated]="rla.isActive"
    >
      <mat-icon
        [fontSet]="rla.isActive ? 'material-icons' : 'material-icons-outlined'"
        matListItemIcon
        >{{ item().icon }}
      </mat-icon>
      @if (!collapsed()) {
      <span matListItemTitle>{{ item().label }}</span>
      } @if (item().subItems) {
      <span matListItemMeta>
        @if (nestedMenuOpen()) {
        <mat-icon>expand_less</mat-icon>
        } @else {
        <mat-icon>expand_more</mat-icon>
        }
      </span>
      }
    </a>
    @if (item().subItems && nestedMenuOpen()) {
    <div @expandContractMenu>
      @for (subItem of item().subItems; track $index) {
      <a
        mat-list-item
        class="menu-item "
        [class.indented]="!collapsed()"
        [routerLink]="item().route + '/' + subItem.route"
        routerLinkActive
        #rla="routerLinkActive"
        [activated]="rla.isActive"
      >
        <mat-icon
          [fontSet]="
            rla.isActive ? 'material-icons' : 'material-icons-outlined'
          "
          matListItemIcon
          >{{ subItem.icon }}
        </mat-icon>
        @if (!collapsed()) {
        <span matListItemTitle>{{ subItem.label }}</span>
        }
      </a>
      }
    </div>
    }
  `,
  styles: `
    .indented {
      --mat-list-list-item-leading-icon-start-space: 48px;
    }

    .menu-item {
      border-left: 5px solid;
      border-left-color: rgba(0, 0, 0, 0);
      margin: 4px 0;
    }

    .selected-menu-item {
      border-left-color: var(--primary-color);
      background: rgba(0, 0, 0, 0.05)
    }

    :host * {
      transition: all 500ms ease-in-out;
    }
  `,
})
export class AdminMenuItemComponent {
  item = input.required<MenuItem>();
  collapsed = input(false);

  nestedMenuOpen = signal(false);

  toggleNested() {
    if (!this.item().subItems) {
      return;
    }

    this.nestedMenuOpen.set(!this.nestedMenuOpen());
  }
}
