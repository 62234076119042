import { Component, computed, inject, Input, signal } from '@angular/core';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AvatarModule } from 'ngx-avatars';
import { AdminMenuItemComponent } from './admin-menu-item.component';
import { CompanyStatusComponent } from './company-status.component';
import { AppService } from '@buspro/web/core';

export type MenuItem = {
  icon: string;
  label: string;
  route?: string;
  subItems?: MenuItem[];
  isTenantSpecific: boolean;
};

@Component({
  selector: 'buspro-admin-sidenav',
  standalone: true,
  imports: [
    NgOptimizedImage,
    MatListModule,
    MatIconModule,
    RouterLink,
    AvatarModule,
    NgIf,
    RouterLinkActive,
    AdminMenuItemComponent,
    CompanyStatusComponent,
  ],
  template: `
    @if (currentTenant()) {
    <div class="pt-8 text-center flex flex-col items-center">
      <ngx-avatars
        [size]="profilePicSize()"
        [name]="currentTenant()!.name"
        [src]="currentTenant()!.logoUrl"
      ></ngx-avatars>
      <div
        [style.height]="'3rem'"
        [class.!h-0]="sideNavCollapsed()"
        [class.opacity-0]="sideNavCollapsed()"
      >
        <div class="font-bold ">{{ currentTenant()!.name }}</div>
        <buspro-admin-company-status
          [status]="currentTenant()!.status"
        ></buspro-admin-company-status>
      </div>
    </div>
    }

    <mat-nav-list>
      @for (menuItem of menuItems(); track menuItem) {
      <buspro-admin-menu-item
        [item]="menuItem"
        [collapsed]="sideNavCollapsed()"
      ></buspro-admin-menu-item>
      }
    </mat-nav-list>
  `,
  styles: `

    .menu-item {
      border-left: 5px solid;
      border-left-color: rgba(0, 0, 0, 0);
    }

    .selected-menu-item {
      border-left-color: var(--primary-color);
      background: rgba(0, 0, 0, 0.05)
    }

    :host * {
      transition: all 300ms ease-in-out;
    }
  `,
})
export class AdminSidenavComponent {
  appService = inject(AppService);
  currentTenant = this.appService.currentTenant$;

  menuItems = signal<MenuItem[]>([
    {
      icon: 'widgets',
      label: 'Dashboard',
      route: '/dashboard',
      isTenantSpecific: false,
    },
    {
      icon: 'explore_nearby',
      label: 'Terminals & Bus Stops',
      route: '/standard-places',
      isTenantSpecific: false,
    },
    {
      icon: 'domain',
      label: 'Tenants',
      route: `/tenants`,
      isTenantSpecific: true,
      // subItems: [
      //   {
      //     icon: 'lists',
      //     label: 'Directory',
      //     route: 'directory',
      //     isTenantSpecific: false
      //   },
      //   {
      //     icon: 'sim_card',
      //     label: 'SIM Cards',
      //     route: `/${this.currentTenant()?.id}/sims`,
      //     isTenantSpecific: true
      //   },
      // ]
    },
  ]);

  sideNavCollapsed = signal(false);
  profilePicSize = computed(() => (this.sideNavCollapsed() ? 32 : 100));

  @Input() set collapsed(val: boolean) {
    this.sideNavCollapsed.set(val);
  }
}
