import { Component, computed, inject, OnInit, Signal } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconButton } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';
import { AvatarModule } from 'ngx-avatars';
import { FormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  LocalAuthService,
  ResponsiveService,
  TokenService,
  WEB_CONFIG,
  WebAppEnvironment,
} from '@buspro/web/core';
import {
  AdminSidenavComponent,
  BusinessSwitcherComponent,
  UserAuthComponent,
} from '../components';
import { AdminMenuService } from '@buspro/web/admin/core';
import { NGXLogger } from 'ngx-logger';
import { NzTagComponent } from 'ng-zorro-antd/tag';

@UntilDestroy()
@Component({
  selector: 'buspro-admin-workspace-layout',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatIconButton,
    AdminSidenavComponent,
    RouterOutlet,
    NgOptimizedImage,
    AvatarModule,
    RouterLink,
    FormsModule,
    BusinessSwitcherComponent,
    UserAuthComponent,
    NzTagComponent,
  ],
  template: `
    <mat-toolbar
      class="mat-elevation-z3 relative z-10 flex flex-row flex-nowrap pr-8"
    >
      <button (click)="collapsed.set(!collapsed())" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
      <div class="w-48 flex items-center cursor-pointer" routerLink="/">
        <img
          [priority]="1"
          ngSrc="/logo-no-border.png"
          alt="Buspro Logo"
          height="188"
          width="600"
        />

        <nz-tag [nzColor]="envColor">{{ envName }}</nz-tag>
      </div>
      <div class="grow"></div>
      <div>
        <buspro-admin-business-switcher></buspro-admin-business-switcher>
      </div>
      <div class="grow"></div>
      <!-- <div class="flex-initial mx-8">
        <button class="btn btn-ghost" routerLink="/dashboard">
          <mat-icon>domain_add</mat-icon>
          Add Business
        </button>
      </div> -->

      <buspro-admin-user-auth
        (loggingOut)="logout()"
        [currentUser]="authUser$()"
      ></buspro-admin-user-auth>
    </mat-toolbar>
    <mat-sidenav-container
      [autosize]="true"
      class="h-[calc(100vh-64px)] overflow-x-hidden bg-gray-100"
    >
      <mat-sidenav
        class="overflow-x-hidden"
        opened
        [mode]="menuSelectedMode()"
        [style.width]="sideNavWidth()"
      >
        <buspro-admin-sidenav [collapsed]="collapsed()"></buspro-admin-sidenav>
      </mat-sidenav>
      <mat-sidenav-content
        [style.margin-left]="sideNavWidth()"
        class="p-4 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-300 overflow-y-auto bg-gray-100"
      >
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  `,
  styles: `
    mat-sidenav,
    mat-sidenav-content {
      transition: all 300ms ease-in-out;
    }
  `,
})
export class AdminWorkspaceLayoutComponent implements OnInit {
  private tokenService = inject(TokenService);
  private router = inject(Router);
  private authService = inject(LocalAuthService);
  private responsiveService = inject(ResponsiveService);
  private menuService = inject(AdminMenuService);
  private logger = inject(NGXLogger);
  protected readonly environment = inject<WebAppEnvironment>(WEB_CONFIG);

  collapsed = this.menuService.collapsed;
  sideNavWidth = this.menuService.sideNavWidth;
  menuSelectedMode: Signal<MatDrawerMode> = computed(() => {
    if (this.responsiveService.smallWidth()) {
      return 'over';
    }
    return 'side';
  });

  authUser$ = this.authService.currentUser$;

  get envName() {
    return this.environment.env;
  }

  get envColor() {
    const envName = this.environment.env;
    switch (envName) {
      case 'DEV':
        return 'warning';
      case 'STAGING':
        return 'success';
      case 'PROD':
        return 'processing';
      default:
        return 'warning';
    }
  }

  ngOnInit(): void {
    this.tokenService.logout$.pipe(untilDestroyed(this)).subscribe(async () => {
      await this.router.navigate(['/passport/login']);
    });
  }

  logout() {
    this.logger.info('Logging out user');
    this.authService
      .signOutFromServer()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.authService.logout();
        },
        error: () => {
          this.authService.logout();
        },
      });
  }
}
